import axios from "axios";
// let serverurl = "https://api.workflow.gzonetechph.com";
// let serverurl = "https://beta.workflow.gzonetechph.com";
// let serverurl = "https://workflowapi.greenzoneph.com";
let serverurl = "https://workflowapi.usiphil.com";
// let serverurl = "http://45.202.22.186:14143/backend/api.workflow/";
let key = "?key=PocketHR@20190208&type=web";

export function loginUser(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
