import { Route, Routes } from "react-router-dom";
import ".././../App.css";
import Header from "../layout/header/Header";
import Login from "../login/main_login";
import Systems from "../layout/systems/systems";
import ProtectedRoutes from "./ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
function RoutePage() {
  const user_account = useSelector((state) => state.LoginReducer.user_account);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {user_account.length == 0 ? (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Systems />} />
            <Route element={<ProtectedRoutes />}></Route>
          </Routes>
        </>
      )}
    </div>
  );
}

export default RoutePage;
