const initialState = {
  signin_col: 0,
  signup_col: 0,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "signin_col":
      return {
        ...action.data,
        signin_col: action.data
      };
    case "signup_col":
      return {
        ...action.data,
        signup_col: action.data
      };
    default:
      return state;
  }
};
export default dataReducer;
