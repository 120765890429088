import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import "../css/layout/login.css";


const Cover = () => {
  return (
    <div className="cover-login ">
      <Container className="pos-relative">
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={0}
          className="max-height"
        >
          <Typography variant="h3" className="white-color">
            Welcome Back!
          </Typography>
          <Typography variant="caption" className="white-color">
            To keep connected with us please login <br/> with your employee info
          </Typography>
        </Stack>
      </Container>
      {/* <div className=" cover-conbtn ">
        <Button
          variant="contained"
          className=" main-btcolor"
          startIcon={<ArrowForwardIosIcon />}
        >
          Sign in
        </Button>
      </div> */}
    </div>
  );
};

export default Cover;
