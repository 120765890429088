import {ACTION_USERS} from '../action/actionUsers/ActionTypes'
const initialState = {
  user_account: [],
  jwt: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_USERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
